var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "18",
        viewBox: "0 0 16 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.54128 16C1.54128 17.1 2.52722 18 3.73225 18H12.4961C13.7011 18 14.6871 17.1 14.6871 16V4H1.54128V16ZM15.7825 1H11.9484L10.8529 0H5.37547L4.27999 1H0.445801V3H15.7825V1Z",
          fill: "#606266",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }